const Preloader = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f8f9fa",
        zIndex: 9999,
      }}
    >
      <img
        src="/assets/img/ABL_logo_transparent.png.png"
        alt="AccessBank Logo"
        style={{
          width: "300px",
          height: "200px",
          animation: "pulse 2s infinite",
        }}
      />
      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </div>
  );
};

export default Preloader;

import axios from "axios";
import {
  userLoaded,
  loginSuccess,
  authError,
  logout,
  refreshTokenSuccess,
} from "../reducers/authReducer";

const API_URL = process.env.REACT_APP_API_URL;

const getAuthHeader = (getState) => {
  const token = getState().auth.token;
  return token ? { Authorization: `Bearer ${token}` } : {};
};

export const loadUser = () => async (dispatch, getState) => {
  if (!getState().auth.token) {
    dispatch(authError("No token found"));
    return;
  }

  try {
    const res = await axios.get(`${API_URL}/auth/me`, {
      headers: getAuthHeader(getState),
    });
    dispatch(userLoaded(res.data.data));
  } catch (err) {
    if (err.response && err.response.status === 401) {
      dispatch(logout());
    } else {
      dispatch(authError("Failed to load user"));
    }
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    const res = await axios.post(`${API_URL}/auth/login`, { email, password });
    dispatch(loginSuccess(res.data));
    await dispatch(loadUser());
    return { success: true };
  } catch (err) {
    const errorMessage =
      err.response?.data?.error ||
      "Login failed. Please check your credentials.";
    dispatch(authError(errorMessage));
    return { success: false, error: errorMessage };
  }
};

export const logoutUser = () => async (dispatch, getState) => {
  try {
    const { auth } = getState();
    await axios.get(`${API_URL}/auth/logout`, {
      headers: {
        Authorization: `Bearer ${auth.token}`,
      },
    });
  } catch (err) {
    console.error(
      "Logout error:",
      err.response ? err.response.data : err.message
    );
  } finally {
    // Dispatch logout action regardless of API call success
    dispatch(logout());
    // Clear any persisted state
    localStorage.removeItem("persist:root");
  }
};

export const refreshToken = () => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${API_URL}/auth/refresh`,
      {},
      { headers: getAuthHeader(getState) }
    );
    dispatch(refreshTokenSuccess(res.data));
    return true;
  } catch (err) {
    dispatch(logout());
    return false;
  }
};

export const setupAutoRefresh = () => (dispatch, getState) => {
  setInterval(async () => {
    if (getState().auth.token) {
      await dispatch(refreshToken());
    }
  }, 15 * 60 * 1000); // Refresh every 15 minutes
};

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tariffs: [],
  tariff: null,
  loading: false,
  error: null,
};

const tariffSlice = createSlice({
  name: "tariff",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    getTariffsSuccess: (state, action) => {
      state.tariffs = action.payload;
      state.loading = false;
    },
    getTariffSuccess: (state, action) => {
      state.tariff = action.payload;
      state.loading = false;
    },
    createTariffSuccess: (state, action) => {
      state.tariffs.push(action.payload);
      state.loading = false;
    },
    updateTariffSuccess: (state, action) => {
      const index = state.tariffs.findIndex(
        (tariff) => tariff._id === action.payload._id
      );
      if (index !== -1) {
        state.tariffs[index] = action.payload;
      }
      state.loading = false;
    },
    deleteTariffSuccess: (state, action) => {
      state.tariffs = state.tariffs.filter(
        (tariff) => tariff._id !== action.payload
      );
      state.loading = false;
    },
    tariffError: (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    },
  },
});

export const {
  setLoading,
  getTariffsSuccess,
  getTariffSuccess,
  createTariffSuccess,
  updateTariffSuccess,
  deleteTariffSuccess,
  tariffError,
} = tariffSlice.actions;
export default tariffSlice.reducer;

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { encryptTransform } from "redux-persist-transform-encrypt";
import expireReducer from "redux-persist-expire";
import authReducer from "./reducers/authReducer";
import userReducer from "./reducers/userReducer";
import bannerReducer from "./reducers/bannerReducer";
import teamMemberReducer from "./reducers/teamMemberReducer";
import faqReducer from "./reducers/faqReducer";
import tariffReducer from "./reducers/tariffReducer";
import serviceReducer from "./reducers/serviceReducer";
import testimonialReducer from "./reducers/testimonialReducer";
import blogReducer from "./reducers/blogReducer";
import jobReducer from "./reducers/jobReducer";
import reportReducer from "./reducers/reportReducer";
import contactReducer from "./reducers/contactSlice";

const encryptConfig = {
  secretKey: process.env.REACT_APP_ENCRYPTION_KEY,
  onError: function (error) {
    console.error("Encryption error:", error);
  },
};

const rootPersistConfig = {
  key: "root",
  storage,
  transforms: [encryptTransform(encryptConfig)],
  whitelist: ["auth"], // only persist auth
};

const authPersistConfig = {
  key: "auth",
  storage,
  transforms: [
    expireReducer("auth", {
      expireSeconds: 1200, // 20 minutes
      expiredState: {
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        error: null,
      },
      autoExpire: true,
    }),
  ],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  user: userReducer,
  banner: bannerReducer,
  teamMember: teamMemberReducer,
  faq: faqReducer,
  tariff: tariffReducer,
  service: serviceReducer,
  testimonial: testimonialReducer,
  blog: blogReducer,
  job: jobReducer,
  report: reportReducer,
  contact: contactReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

export const persistor = persistStore(store);
export default store;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  users: [],
  user: null,
  loading: false,
  error: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    getUsersSuccess: (state, action) => {
      state.users = action.payload;
      state.loading = false;
      state.error = null;
    },
    getUserSuccess: (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    createUserSuccess: (state, action) => {
      state.users.push(action.payload);
      state.loading = false;
      state.error = null;
    },
    updateUserSuccess: (state, action) => {
      const index = state.users.findIndex(
        (user) => user._id === action.payload._id
      );
      if (index !== -1) {
        state.users[index] = action.payload;
      }
      state.user = action.payload;
      state.loading = false;
      state.error = null;
    },
    deleteUserSuccess: (state, action) => {
      state.users = state.users.filter((user) => user._id !== action.payload);
      state.loading = false;
      state.error = null;
    },
    userError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  setLoading,
  getUsersSuccess,
  getUserSuccess,
  createUserSuccess,
  updateUserSuccess,
  deleteUserSuccess,
  userError,
} = userSlice.actions;
export default userSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  jobs: [],
  job: null,
  loading: false,
  error: null,
};

const jobSlice = createSlice({
  name: "job",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    getJobsSuccess: (state, action) => {
      state.jobs = action.payload;
      state.loading = false;
    },
    getJobSuccess: (state, action) => {
      state.job = action.payload;
      state.loading = false;
    },
    createJobSuccess: (state, action) => {
      state.jobs.push(action.payload);
      state.loading = false;
    },
    updateJobSuccess: (state, action) => {
      const index = state.jobs.findIndex(
        (job) => job._id === action.payload._id
      );
      if (index !== -1) {
        state.jobs[index] = action.payload;
      }
      state.loading = false;
    },
    deleteJobSuccess: (state, action) => {
      state.jobs = state.jobs.filter((job) => job._id !== action.payload);
      state.loading = false;
    },
    jobError: (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    },
  },
});

export const {
  setLoading,
  getJobsSuccess,
  getJobSuccess,
  createJobSuccess,
  updateJobSuccess,
  deleteJobSuccess,
  jobError,
} = jobSlice.actions;
export default jobSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  banners: [],
  banner: null,
  loading: false,
  error: null,
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    getBannersSuccess: (state, action) => {
      state.banners = action.payload;
      state.loading = false;
    },
    getBannerSuccess: (state, action) => {
      state.banner = action.payload;
      state.loading = false;
    },
    createBannerSuccess: (state, action) => {
      state.banners.push(action.payload);
      state.loading = false;
    },
    updateBannerSuccess: (state, action) => {
      const index = state.banners.findIndex(
        (banner) => banner._id === action.payload._id
      );
      if (index !== -1) {
        state.banners[index] = action.payload;
      }
      state.loading = false;
    },
    deleteBannerSuccess: (state, action) => {
      state.banners = state.banners.filter(
        (banner) => banner._id !== action.payload
      );
      state.loading = false;
    },
    bannerError: (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    },
  },
});

export const {
  setLoading,
  getBannersSuccess,
  getBannerSuccess,
  createBannerSuccess,
  updateBannerSuccess,
  deleteBannerSuccess,
  bannerError,
} = bannerSlice.actions;
export default bannerSlice.reducer;

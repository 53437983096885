import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  blogs: [],
  blog: null,
  loading: false,
  error: null,
};

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    getBlogsSuccess: (state, action) => {
      state.blogs = action.payload;
      state.loading = false;
    },
    getBlogSuccess: (state, action) => {
      state.blog = action.payload;
      state.loading = false;
    },
    createBlogSuccess: (state, action) => {
      state.blogs.push(action.payload);
      state.loading = false;
    },
    updateBlogSuccess: (state, action) => {
      const index = state.blogs.findIndex(
        (blog) => blog._id === action.payload._id
      );
      if (index !== -1) {
        state.blogs[index] = action.payload;
      }
      state.loading = false;
    },
    deleteBlogSuccess: (state, action) => {
      state.blogs = state.blogs.filter((blog) => blog._id !== action.payload);
      state.loading = false;
    },
    blogError: (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export const {
  setLoading,
  getBlogsSuccess,
  getBlogSuccess,
  createBlogSuccess,
  updateBlogSuccess,
  deleteBlogSuccess,
  blogError,
  clearError,
} = blogSlice.actions;
export default blogSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  service: null,
  loading: false,
  error: null,
};

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    getServicesSuccess: (state, action) => {
      state.services = action.payload;
      state.loading = false;
    },
    getServiceSuccess: (state, action) => {
      state.service = action.payload;
      state.loading = false;
    },
    createServiceSuccess: (state, action) => {
      state.services.push(action.payload);
      state.loading = false;
    },
    updateServiceSuccess: (state, action) => {
      const index = state.services.findIndex(
        (service) => service._id === action.payload._id
      );
      if (index !== -1) {
        state.services[index] = action.payload;
      }
      state.loading = false;
    },
    deleteServiceSuccess: (state, action) => {
      state.services = state.services.filter(
        (service) => service._id !== action.payload
      );
      state.loading = false;
    },
    serviceError: (state, action) => {
      state.loading = false;
      state.error = action.payload || "An error occurred";
    },
  },
});

export const {
  setLoading,
  getServicesSuccess,
  getServiceSuccess,
  createServiceSuccess,
  updateServiceSuccess,
  deleteServiceSuccess,
  serviceError,
} = serviceSlice.actions;

export default serviceSlice.reducer;
